























































































































































































































































































































.container {
    background-color: #f8f8f8;
    height: 100%;
	font-family: PingFangSC-Regular;
    .content {
        background-color: #f8f8f8;
        padding-bottom: 1rem;
    }
    .head_right {
        color: #333333;
        font-size: 14px;
    }
}
.user_msg {
    background-color: #ffffff;
    margin: 10px;
    border-radius: 8px;
    // box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
    .content-top {
        margin: 0 0.21rem;
        .all_cout {
            display: flex;
            padding: 0.36rem 0;
			font-family: PingFangSC-Medium;
            .con_left {
				font-family: PingFangSC-Medium;
                color: #333333;
                font-size: 0.28rem;
                font-weight: 600;
            }
            .con_right {
                color: #333333;
                font-size: 0.28rem;
                font-weight: 600;
                margin-left: 0.28rem;
            }
        }
    }
    .content1 {
        padding: 0.12rem 0.2rem 0.38rem 0.2rem;
        .list {
            display: flex;
            align-items: center;
            padding: 0.08rem 0;
            .con_left {
                // width: 30%;
				font-family: PingFangSC-Medium;
                color: #333333;
                font-size: 0.28rem;
                font-weight: 550;
                text-align: left;
            }
            .con_right {
                // width: 70%;
                color: #6F7A8B;
                font-size: 0.28rem;
                margin-left: 0.48rem;
                display: flex;
                .right {
                    margin-left: 1.5rem;
                }
                .right-jingxin {
                    color: #13ad8f;
                }
                .right-shenhe {
                    color: #d98181;
                }
            }
			.con_right1 {
				width: 76%;
				color: #6F7A8B;
				font-size: 0.28rem;
				margin-left: 0.2rem;
				display: flex;
				justify-content: space-between;
				.right {
				    // margin-left: 1.5rem;
				}
				.right-jingxin {
				    color: #32BF88;
					font-size: 0.24rem;
				}
				.right-shenhe {
					font-size: 0.24rem;
				    color: #F5465D;
				}
			}
        }
        .button {
            margin-top: 0.28rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .but_left {
                width: 2.92rem;
                height: 0.8rem;
                line-height: 0.75rem;
                // padding: 0.18rem 0.86rem;
                border-radius: 0.08rem;
                text-align: center;
                color: #2E62FF;
                font-size: 0.32rem;
				border: 0.01rem solid #2E62FF;
            }
            .but_right {
                width: 2.92rem;
                height: 0.8rem;
                line-height: 0.75rem;
                // padding: 0.18rem 0.86rem;
                border-radius: 0.08rem;
                text-align: center;
                font-size: 0.32rem;
				color: #2E62FF;
				border: 0.01rem solid #2E62FF;
            }
            .but_right1 {
				width: 2.92rem;
				height: 0.8rem;
				line-height: 0.75rem;
				// padding: 0.18rem 0.86rem;
				border-radius: 0.08rem;
				text-align: center;
				font-size: 0.32rem;
				color: #2E62FF;
				border: 0.01rem solid #2E62FF;
            }
        }
    }
}
.bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    padding: 0;
    .button {
        margin: 0.14rem 0.3rem 0.14rem 0.28rem;
        padding: 0.22rem 2.82rem;
        background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
        color: #FFFFFF;
        font-size: 0.32rem;
        text-align: center;
        border-radius: 0.08rem;
        font-weight: 500;
    }
}
.tan {
    width: 5.6rem;
    height: 2.94rem;
	position: relative;
    .tan_no_txt1 {
        padding-top: 0.64rem;
        padding-left: 0.2rem;
        color: #333333;
        font-size: 0.32rem;
        text-align: center;
        font-weight: 500;
        color: #333333;
    }
    .tan_no_txt2 {
        margin-top: 0.12rem;
        color: #333333;
        font-size: 0.24rem;
        color: #666666;
        text-align: center;
        .right {
            color: #2E62FF;
        }
    }
    .tan_no_button {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
        display: flex;
        justify-content: space-between;
		height: 0.8rem;
		line-height: 0.8rem;
		text-align: center;
		border-top: 0.01rem solid #D3D9DE;
        .yes {
			flex: 1;
            color: #2E62FF;
			font-size: 0.28rem;
			border-right: 0.01rem solid #D3D9DE;
        }
        .no {
			flex: 1;
            color: #2E62FF;
        }
    }
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;